const companyName = "(주)엠투엠테크"
const companyNameEn = "M2MTech Co., Ltd"
const companyAdd = "경기도 성남시 수정구 청계산로 686 533, 534호"
const companyAddEn =
  "#533~534, 686, Cheonggyesan-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do"
const companyTel = "031-751-9070"
const companyTelEn = "+82 31-751-9070"
const companyFax = "02-6008-6897"
const companyFaxEn = "+82 2-6008-6897"
const companyMail = "info@m2mtech.co.kr"
const companyHp = "m2mtech.co.kr"

export {
  companyName,
  companyNameEn,
  companyAdd,
  companyAddEn,
  companyTel,
  companyTelEn,
  companyFax,
  companyFaxEn,
  companyMail,
  companyHp,
}
